import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { SectionTilesProps } from '../../utils/SectionProps';
import Image from '../elements/Image';
import UniverseComponent from '../elements/UniverseComponent';
import { useSpring, animated } from 'react-spring';
import MobileUniverse from './partials/MobileUniverse';

const propTypes = {
  ...SectionTilesProps.types,
};

const defaultProps = {
  ...SectionTilesProps.defaults,
};

const animationProps = {
  world: {
    map: {
      backgroundSize: 'auto 150%',
      backgroundPositionX: '50%',
      backgroundPositionY: '0%',
    },
    marker: {
      top: '50%',
      left: '50%',
    },
  },
  valdt: {
    map: {
      backgroundSize: 'auto 265%',
      backgroundPositionX: '55%',
      backgroundPositionY: '15%',
    },
    marker: {
      top: '30%',
      left: '62%',
    },
  },
  vengewood: {
    map: {
      backgroundSize: 'auto 310%',
      backgroundPositionX: '80%',
      backgroundPositionY: '0%',
    },
    marker: {
      top: '20%',
      left: '70%',
    },
  },
  fathoms: {
    map: {
      backgroundSize: 'auto 315%',
      backgroundPositionX: '89%',
      backgroundPositionY: '11%',
    },
    marker: {
      top: '42%',
      left: '72%',
    },
  },
  vast: {
    map: {
      backgroundSize: 'auto 300%',
      backgroundPositionX: '85%',
      backgroundPositionY: '43.5%',
    },
    marker: {
      top: '40%',
      left: '58%',
    },
  },
  glades: {
    map: {
      backgroundSize: 'auto 250%',
      backgroundPositionX: '55%',
      backgroundPositionY: '44%',
    },
    marker: {
      top: '58%',
      left: '72%',
    },
  },
};

const Universe = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {
  const [offsetY, setOffsetY] = useState(0);
  const [mobileView, setMobileView] = useState(false);
  const handleScroll = () => setOffsetY(window.pageYOffset);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const outerClasses = classNames(
    'universe section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'universe-inner section-inner pt-64 pos-relative',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const tilesClasses = classNames(
    'tiles-wrap center-content',
    pushLeft && 'push-left'
  );

  const [location, setLocation] = useState('world');

  const handleLocation = (newLocation) => {
    location === newLocation ? setLocation('world') : setLocation(newLocation);
  };

  const [springMapProps, setSpringMapProps] = useSpring(() => {
    return {
      to: {
        backgroundSize: 'auto 150%',
        backgroundPositionX: '50%',
        backgroundPositionY: '0%',
      },
    };
  });

  const [springMarkerProps, setSpringMarkerProps] = useSpring(() => {
    return {
      to: {
        top: '50%',
        left: '50%',
      },
    };
  });

  useEffect(() => {
    setSpringMapProps(() => ({
      to: {
        ...animationProps[location].map,
      },
    }));
    setSpringMarkerProps(() => ({
      to: {
        ...animationProps[location].marker,
      },
    }));
  }, [location, setSpringMapProps, setSpringMarkerProps]);

  useEffect(() => {
    setMobileView(window.innerWidth < 1080);
  }, [window.innerWidth]);

  return (
    <section {...props} className={outerClasses} id="universe">
      <div className="container-fluid">
        <div className={innerClasses}>
          <div className={tilesClasses}>
            <div className="universe-container">
              {mobileView ? (
                <MobileUniverse />
              ) : (
                <>
                  <animated.div
                    className="universe-map"
                    style={springMapProps}
                  />
                  <animated.img
                    src={require('../../assets/images/sections/universe/indicator.png')}
                    alt="GPS marker"
                    className="gps-marker"
                    style={{
                      ...springMarkerProps,
                      opacity: location === 'world' ? '0' : '1',
                    }}
                  />
                  <div className="content">
                    <div className="universe-header">
                      <h3
                        className="reveal-from-bottom"
                        data-reveal-delay="200"
                      >
                        THE UNIVERSE
                      </h3>
                      <p className="reveal-from-bottom" data-reveal-delay="400">
                        After 100 years of war, the six dynastic formed a union.
                        Together, they face a mysterious force threatens the
                        Kingdom.
                      </p>
                    </div>
                    <UniverseComponent
                      title="The Veldt"
                      content={
                        'Home to the humans, the Veldt is a rich land occupied by happy homes, sprawling farms, and bountiful markets, all under the protection of The Phalanx, the magnificent medieval castle that serves as a nexus for trade across the entire continent.'
                      }
                      imageUrl="land1.png"
                      handleLocation={handleLocation}
                      location="valdt"
                      isOpen={location === 'valdt'}
                    />
                    <UniverseComponent
                      title="Vengewood Jungle"
                      content={
                        'This thick rainforest is home to the Kingdom of the Horn, anthropomorphized animals whose simple structures dot the base of Mt. Shine, the world’s only source of magical Armorite – a resource they’ve fought over for generations.'
                      }
                      imageUrl="land2.png"
                      handleLocation={handleLocation}
                      location="vengewood"
                      isOpen={location === 'vengewood'}
                    />
                    <UniverseComponent
                      title="The Fathoms"
                      content={
                        'The ocean south of Mt. Shine is inhabited by the Costraca, a union of sea-dwelling races who breathe both underwater and on land. They too lay claim to Mt. Shine—known in their ancient scrolls as the Creation Peak—and they’ll stop at nothing to rid their sacred mountain of the filth that is the Horn.'
                      }
                      imageUrl="land3.png"
                      handleLocation={handleLocation}
                      location="fathoms"
                      isOpen={location === 'fathoms'}
                    />
                    <UniverseComponent
                      title="The Hulking Vast"
                      content={
                        'Once home to an advanced civilization, this long-abandoned city has now been reclaimed by the ravages of nature. It’s inhabited by the enigmatic Warri, a long-lost splinter of the humans who’ve developed mysterious powers due to their exposure to Armorite dust.'
                      }
                      imageUrl="land4.png"
                      handleLocation={handleLocation}
                      location="vast"
                      isOpen={location === 'vast'}
                    />
                    <UniverseComponent
                      title="The Ghosted Glades"
                      content={
                        'Once the most vibrant region in the world, the Ghosted Glades was the site of the Decimation, a mysterious event that spelled the downfall of the Ancestors. Since then, it’s been pummeled by an eternal tempest known as the Darkstorm. The glades are home to the Autumn, a sorcerer society of outcasts from all other races, but few knew what horrors lurk further within.'
                      }
                      imageUrl="land5.png"
                      handleLocation={handleLocation}
                      location="glades"
                      isOpen={location === 'glades'}
                    />
                  </div>
                </>
              )}
            </div>
          </div>

          <Image
            style={{ transform: `translateY(${-offsetY * 0.5}px)` }}
            id="universe-armorite"
            src={require('./../../assets/images/sections/universe/Armorite.png')}
            alt="Hero"
          />
        </div>
      </div>
    </section>
  );
};

Universe.propTypes = propTypes;
Universe.defaultProps = defaultProps;

export default Universe;
