import React, { useRef, useEffect, useState } from 'react';
import { useLocation, Switch, Redirect } from 'react-router-dom';
import AppRoute from './utils/AppRoute';
import ScrollReveal from './utils/ScrollReveal';
import LoadingScreen from './components/elements/LoadingScreen';

// Layouts
import LayoutDefault from './layouts/LayoutDefault';

// Views 
import Home from './views/Home';
import Profile from './views/Profile';
import Comic from './views/Comic';
import Mint from './views/Mint';
import ErrorPage from "./views/ErrorPage";
import PrivacyPolicyPage from './views/PrivacyPolicy';

const App = () => {

  const [loading, setLoading] = useState(true);
  const childRef = useRef();
  let location = useLocation();

  useEffect(() => {
    if(!loading){
      document.body.classList.add('is-loaded')
      childRef.current.init();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, loading]);

  
  useEffect(() => {
    const timeout = setTimeout(() => setLoading(false), 3000);
    return () => clearTimeout(timeout);
  }, []);

  if(loading){
    return <LoadingScreen pageLoad={true}/>
  }

  return (
    <ScrollReveal
      ref={childRef}
      children={() => (
        <Switch>
          <AppRoute exact path="/" component={Home} layout={LayoutDefault} />
          <AppRoute exact path="/profile" component={Profile} layout={LayoutDefault} />
          <AppRoute exact path="/comic/:id/:contract?" component={Comic} layout={LayoutDefault} />
          <AppRoute exact path="/mint">
            <Redirect to="/" />
          </AppRoute>
          <AppRoute exact path="/mint/:type" component={Mint} />
          <AppRoute exact path="/privacy-policy" component={PrivacyPolicyPage} layout={LayoutDefault} />
          <AppRoute exact path="*" component={ErrorPage} layout={LayoutDefault} />
        </Switch>
      )} />
  );
}

export default App;