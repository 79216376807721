import { utils, providers } from 'near-api-js';
export async function view(account_id, method, args) {
  const { network } = window.selector.options;
  const provider = new providers.JsonRpcProvider({ url: network.nodeUrl });
  return await provider
    .query({
      request_type: 'call_function',
      account_id: account_id,
      method_name: method,
      args_base64: Buffer.from(JSON.stringify(args)).toString('base64'),
      finality: 'optimistic',
    })
    .then((res) => JSON.parse(Buffer.from(res.result).toString()));
}
// Change methods
export async function call(method, args, gas = 2000000000000, deposit = 0) {
  const {
    contract: { contractId },
    accounts,
  } = window.selector.store.getState();
  const { accountId } = accounts[0];
  const wallet = await window.selector.wallet();
  return wallet.signAndSendTransaction({
    signerId: accountId,
    receiverId: contractId,
    actions: [
      {
        type: 'FunctionCall',
        params: {
          methodName: method,
          args,
          gas,
          deposit: utils.format.parseNearAmount(deposit.toString()),
        },
      },
    ],
  });
}

export function validateEmail(email) {
  return !email.match(
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
}
