import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import FooterNav from "./partials/FooterNav";
import FooterSocial from "./partials/FooterSocial";
import Image from "../elements/Image";
import { Link } from "react-router-dom";

const propTypes = {
  topOuterDivider: PropTypes.bool,
  topDivider: PropTypes.bool,
};

const defaultProps = {
  topOuterDivider: false,
  topDivider: false,
};

const Footer = ({ className, topOuterDivider, topDivider, ...props }) => {
  const classes = classNames(
    "site-footer center-content-mobile",
    topOuterDivider && "has-top-divider",
    className
  );

  return (
    <footer {...props} className={classes}>
      <div className="container">
        <div
          className={classNames(
            "site-footer-inner",
            topDivider && "has-top-divider"
          )}
        >
          <div className="footer-bottom space-between text-xxs invert-order-desktop fs-18">
            <div className="footer-socials">
              <Link
                target={"_blank"}
                to={{ pathname: "https://discord.gg/armoredkingdom" }}
              >
                <Image
                  src={require("../../assets/images/sections/footer/discord.png")}
                  alt="Discord Logo"
                />
              </Link>
              <Link
                target={"_blank"}
                to={{ pathname: "https://twitter.com/ArmoredKingdom" }}
              >
                <Image
                  src={require("../../assets/images/sections/footer/twitter.png")}
                  alt="twitter Logo"
                />
              </Link>
            </div>
            <div>
              <div className="footer-left">
                <Image
                  style={{ height: "10vh" }}
                  src={require("../../assets/images/armored_icon1.png")}
                />
                <div className="footer-copyright">
                  COPYRIGHT <a href="#">ARMORED KINGDOM</a>
                </div>
              </div>
            </div>
            <div>
              <a href="/privacy-policy">PRIVACY POLICY</a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

Footer.propTypes = propTypes;
Footer.defaultProps = defaultProps;

export default Footer;
