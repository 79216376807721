import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { SectionProps } from "../../utils/SectionProps";
import SectionHeader from "./partials/SectionHeader";
import Image from "../elements/Image";

const propTypes = {
  ...SectionProps.types,
  split: PropTypes.bool,
};

const defaultProps = {
  ...SectionProps.defaults,
  split: false,
};

const Integrate = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  split,
  ...props
}) => {
  const outerClasses = classNames(
    "cta section center-content-mobile mt-64 reveal-from-bottom",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "cta-inner section-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider",
    split && "cta-split"
  );

  const sectionHeader = {
    paragraph: "Immerse yourself in the Armored Kingdom Universe",
  };

  return (
    <section {...props} className={outerClasses}>  
      <div className="container-sm" style={{marginBotton: 0}}>
      <h3 className="center-content tt-u">{sectionHeader.paragraph} </h3>
        <div className={innerClasses}>   
          <div className="integrate-item-list">
            <div className="integrate-item m-8">
              <Image
                src={require("../../assets/images/sections/integrate/image1.png")}
                width={312}
                height={280}
              />
              <h6>The Comic</h6>
              <p>Dig into the origins with a new issue each month</p>
            </div>
            <div className="integrate-item m-8">
              <Image
              src={require("../../assets/images/sections/integrate/image2.png")}
                width={312}
                height={280}
              />
              <h6>The Game</h6>
              <p>Own your gear and play for ascendance</p>
            </div>
            <div className="integrate-item m-8">
              <Image
              src={require("../../assets/images/sections/integrate/image3.png")}
                width={312}
                height={280}
              />
              <h6>The Show</h6>
              <p>Engross yourself in the story and see your game pieces come to life</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

Integrate.propTypes = propTypes;
Integrate.defaultProps = defaultProps;

export default Integrate;
