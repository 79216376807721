import React, { useEffect, useState } from 'react';
import { animated, useSpring } from 'react-spring';
import { Swiper, SwiperSlide } from 'swiper/react';
import style from './MobileUniverse.module.css';

const MobileUniverse = () => {
  const [offset, setOffset] = useState(window.innerWidth);
  const [selectedIdx, setSelectedIdx] = useState(0);

  const renderArr = [
    {
      title: 'The Veldt',
      desc: 'Home to the humans, the Veldt is a rich land occupied by happy homes, sprawling farms, and bountiful markets, all under the protection of The Phalanx, the magnificent medieval castle that serves as a nexus for trade across the entire continent.',
    },
    {
      title: 'Vengewood Jungle',
      desc: 'This thick rainforest is home to the Kingdom of the Horn, anthropomorphized animals whose simple structures dot the base of Mt. Shine, the world’s only source of magical Armorite – a resource they’ve fought over for generations.',
    },
    {
      title: 'the fathoms',
      desc: 'The ocean south of Mt. Shine is inhabited by the Costraca, a union of sea-dwelling races who breathe both underwater and on land. They too lay claim to Mt. Shine—known in their ancient scrolls as the Creation Peak—and they’ll stop at nothing to rid their sacred mountain of the filth that is the Horn.',
    },
    {
      title: 'The Hulking Vast',
      desc: 'Once home to an advanced civilization, this long-abandoned city has now been reclaimed by the ravages of nature. It’s inhabited by the enigmatic Warri, a long-lost splinter of the humans who’ve developed mysterious powers due to their exposure to Armorite dust.',
    },
    {
      title: 'The Ghosted Glades',
      desc: 'Once the most vibrant region in the world, the Ghosted Glades was the site of the Decimation, a mysterious event that spelled the downfall of the Ancestors. Since then, it’s been pummeled by an eternal tempest known as the Darkstorm. The glades are home to the Autumn, a sorcerer society of outcasts from all other races, but few knew what horrors lurk further within.',
    },
  ];

  const [mapStyle, setMapStyle] = useSpring(() => ({
    to: {
      backgroundSize: 'auto 100%',
      backgroundPositionX: '75%',
      backgroundPositionY: '55%',
    },
  }));

  const [markerStyle, setMarkerStyle] = useSpring(() => ({
    to: {
      opacity: '0',
      top: '50%',
      left: '50%',
    },
  }));

  const handleResize = () => setOffset(window.innerWidth);
  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const animationProps = {
      world: {
        map: {
          backgroundSize: 'auto 100%',
          backgroundPositionX: '75%',
          backgroundPositionY: '55%',
        },
        marker: {
          top: '50%',
          left: '50%',
          opacity: '0',
        },
      },
      valdt: {
        map: {
          backgroundSize: 'auto 150%',
          backgroundPositionX: '65%',
          backgroundPositionY: '20%',
        },
        marker: {
          top: '17%',
          left: `${35 + offset / 50}%`,
          opacity: '1',
        },
      },
      vengewood: {
        map: {
          backgroundSize: 'auto 180%',
          backgroundPositionX: '82%',
          backgroundPositionY: '0%',
        },
        marker: {
          top: '10%',
          left: `${35 + offset / 25}%`,
          opacity: '1',
        },
      },
      fathoms: {
        map: {
          backgroundSize: 'auto 180%',
          backgroundPositionX: '91%',
          backgroundPositionY: '10%',
        },
        marker: {
          top: '30%',
          left: `${42 + offset / 25}%`,
          opacity: '1',
        },
      },
      hulking: {
        map: {
          backgroundSize: 'auto 180%',
          backgroundPositionX: '83%',
          backgroundPositionY: '61%',
        },
        marker: {
          top: '23%',
          left: `${40 + offset / 40}%`,
          opacity: '1',
        },
      },
      glades: {
        map: {
          backgroundSize: 'auto 155%',
          backgroundPositionX: '65%',
          backgroundPositionY: '95%',
        },
        marker: {
          top: '23%',
          left: `${40 + offset / 60}%`,
          opacity: '1',
        },
      },
    };

    const location = Object.keys(animationProps)[selectedIdx];
    setMapStyle(() => ({
      to: {
        ...animationProps[location].map,
      },
    }));

    setMarkerStyle(() => ({
      to: {
        ...animationProps[location].marker,
      },
    }));
  }, [selectedIdx, setMapStyle, setMarkerStyle, offset]);

  return (
    <>
      <animated.div style={mapStyle} className={style.mobileMap} />
      <animated.img
        src={require('../../../assets/images/sections/universe/indicator.png')}
        alt="GPS marker"
        className="gps-marker"
        style={{
          ...markerStyle,
        }}
      />
      <div>
        <Swiper
          loop
          centeredSlides
          className={style.container}
          onSlideChange={({ realIndex }) => setSelectedIdx(realIndex)}
        >
          <SwiperSlide className={style.item}>
            <div className={style.mainCard}>
              <h1 className="mb-16">The Universe</h1>
              <p
                style={{
                  paddingRight: '32px',
                  fontSize: '16px',
                }}
              >
                After 100 years of war, the six dynasties formed a union.
                Together, they face a mysterious force threatens the Kingdom.
              </p>
            </div>
          </SwiperSlide>
          {renderArr.map((item) => (
            <SwiperSlide key={item.title} className={style.item}>
              <div className={style.mainCard}>
                <h1>{item.title}</h1>
                <p>{item.desc}</p>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      <div className={style.backdrop} />
    </>
  );
};

export default MobileUniverse;
