import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { SectionTilesProps } from "../../utils/SectionProps";
import SectionHeader from "./partials/SectionHeader";
import DynastyComponent from "../elements/DynastyComponent";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import Image from "../elements/Image";

const propTypes = {
  ...SectionTilesProps.types,
};

const defaultProps = {
  ...SectionTilesProps.defaults,
};

const Dynasties = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {
  const [offsetY, setOffsetY] = useState(0);
  const handleScroll = () => setOffsetY(window.pageYOffset);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const outerClasses = classNames(
    "testimonial section",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "testimonial-inner section-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  const tilesClasses = classNames(
    "tiles-wrap dynasties-container",
    pushLeft && "push-left",
    "only-desktop-flex"
  );

  const sectionHeader = {
    paragraph: "THE DYNASTIES",
  };

  return (
    <section {...props} className={outerClasses + " pt-100"}>
      <div className="testimonial-middle">
        <div className="container-fluid">
          <div className={innerClasses}>
            <SectionHeader data={sectionHeader} className="center-content" />
            <div className={tilesClasses}>
              <DynastyComponent
                imageUrl="dynasty1.png"
                content={
                  "Inhabiting the Vengewood Jungle at the base of Mt. Shine, the Kingdom of the Horn is a coalition of anthropomorphized animals led by their proud elephantine King Colossus and protected by their most fearsome warriors, the four-armed Gorillakars."
                }
                title={"Kingdom of the Horn"}
                subtitle="Vengewood Jungle"
                id="horn"
              />
              <DynastyComponent
                imageUrl="dynasty2.png"
                content={
                  "Led by King Kitanje, the Abbot of the Abyss, this powerful alliance of amphibious and crustacean creatures have witnessed their sacred mountain and seas desecrated by the land dwelling Kingdom of the Horn for too long, and they’re preparing a strike force to wipe them out for good. The Costracan inner city is too deep underwater for outsiders to reach without the assistance of Armorite gear, so their machinations are secretive to the rest of the world."
                }
                title="The Costraca"
                subtitle="The Fathoms"
                id="costraca"
              />
              <DynastyComponent
                imageUrl="dynasty3.png"
                content={
                  "Under the watch of revered King Assurant, the humans have tried to sow peace among the continent, making their city a hub for diplomacy and a welcome destination for travelers. But they’re well aware that they’re outmatched by both the Kingdom of the Horn and the Costraca, and some of the king’s council believe they should take any opportunity that arises for a pre-emptive strike."
                }
                title="The Humans"
                subtitle="The Veldt"
                id="humans"
              />
              <DynastyComponent
                imageUrl="dynasty4.png"
                content={
                  "The oio is a society of microscopic sentient beings which collectively form a single great intelligence. Their origins are unknown, even to the oio themselves. Ooze-like, the oio can take any form it wishes, but seems to care little for the affairs of the other races, focusing instead on simulations and existentialism."
                }
                title={"The Oio"}
                subtitle="Origins Unknown"
                id="oio"
              />
              <DynastyComponent
                imageUrl="dynasty5.png"
                content={
                  "An enigmatic race who branched off from humanity many generations ago, the Warri live in the dangerous region of the Hulking Vast, where nature has been affected by the dust from Mt. Shine and wildlife has run amok. The Warri are unknown to the rest of Armoria, and theirintentions — and abilities — remain a mystery."
                }
                title={"The Warri"}
                subtitle="The Hulking Vast"
                id="warri"
              />
            </div>
            <div className="only-mobile">
              <Swiper
                spaceBetween={-40}
                slidesPerView={1.2}
                centeredSlides={true}
                loop={true}
                onSlideChange={() => console.log("slide change")}
                onSwiper={(swiper) => console.log(swiper)}
              >
                <SwiperSlide>
                  <div className="pos-relative">
                    <div className="pos-relative">
                      <img
                        className="dynasty-img"
                        src={require("../../assets/images/sections/dynasties/dynasty1.png")}
                        alt="Vengewood Jungle"
                      />
                      <img
                        className="dynasty-overlay"
                        src={require("../../assets/images/sections/dynasties/dynasty_overlay.png")}
                        alt="Dynasty Overlay"
                      />
                    </div>
                    <div className="dynasty-text" id="horn">
                      <div>
                        <h3 className="dynasty-title">{"Kingdom of the Horn"}</h3>
                        <p className="dynasty-subtitle">{"Vengewood Jungle"}</p>
                      </div>
                      <p className="dynasty-content">
                        Inhabiting the Vengewood Jungle at the base of Mt. Shine, the Kingdom of the
                        Horn is a coalition of anthropomorphized animals led by their proud
                        elephantine King Colossus and protected by their most fearsome warriors, the
                        four-armed Gorillakars.
                      </p>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="pos-relative">
                    <div className="pos-relative">
                      <img
                        className="dynasty-img"
                        src={require("../../assets/images/sections/dynasties/dynasty2.png")}
                        alt="The Costraca"
                      />
                      <img
                        className="dynasty-overlay"
                        src={require("../../assets/images/sections/dynasties/dynasty_overlay.png")}
                        alt="Dynasty Overlay"
                      />
                    </div>
                    <div className="dynasty-text" id="costraca">
                      <div>
                        <h3 className="dynasty-title">{"The Costraca"}</h3>
                        <p className="dynasty-subtitle">{"The Fathoms"}</p>
                      </div>
                      <p className="dynasty-content">
                        Led by King Kitanje, the Abbot of the Abyss, this powerful alliance of
                        amphibious and crustacean creatures have witnessed their sacred mountain and
                        seas desecrated by the land- dwelling Kingdom of the Horn for too long, and
                        they’re preparing a strike force to wipe them out for good. The Costracan
                        inner city is too deep underwater for outsiders to reach without the
                        assistance of Armorite gear, so their machinations are secretive to the rest
                        of the world.
                      </p>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="pos-relative">
                    <div className="pos-relative">
                      <img
                        className="dynasty-img"
                        src={require("../../assets/images/sections/dynasties/dynasty3.png")}
                        alt="The Human"
                      />
                      <img
                        className="dynasty-overlay"
                        src={require("../../assets/images/sections/dynasties/dynasty_overlay.png")}
                        alt="Dynasty Overlay"
                      />
                    </div>
                    <div className="dynasty-text" id="humans">
                      <div>
                        <h3 className="dynasty-title">{"The Humans"}</h3>
                        <p className="dynasty-subtitle">{"The Veldt"}</p>
                      </div>
                      <p className="dynasty-content">
                        Under the watch of revered King Assurant, the humans have tried to sow peace
                        among the continent, making their city a hub for diplomacy and a welcome
                        destination for travelers. But they’re well aware that they’re outmatched by
                        both the Kingdom of the Horn and the Costraca, and some of the king’s
                        council believe they should take any opportunity that arises for a
                        pre-emptive strike.
                      </p>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="pos-relative">
                    <div className="pos-relative">
                      <img
                        className="dynasty-img"
                        src={require("../../assets/images/sections/dynasties/dynasty4.png")}
                        alt="The Oio"
                      />
                      <img
                        className="dynasty-overlay"
                        src={require("../../assets/images/sections/dynasties/dynasty_overlay.png")}
                        alt="Dynasty Overlay"
                      />
                    </div>
                    <div className="dynasty-text" id="oio">
                      <div>
                        <h3 className="dynasty-title">{"The Oio"}</h3>
                        <p className="dynasty-subtitle">{"Origins Unknown"}</p>
                      </div>
                      <p className="dynasty-content">
                        The oio is a society of microscopic sentient beings which collectively form
                        a single great intelligence. Their origins are unknown, even to the oio
                        themselves. Ooze-like, the oio can take any form it wishes, but seems to
                        care little for the affairs of the other races, focusing instead on
                        simulations and existentialism.
                      </p>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="pos-relative">
                    <div className="pos-relative">
                      <img
                        className="dynasty-img"
                        src={require("../../assets/images/sections/dynasties/dynasty5.png")}
                        alt="The Warri"
                      />
                      <img
                        className="dynasty-overlay"
                        src={require("../../assets/images/sections/dynasties/dynasty_overlay.png")}
                        alt="Dynasty Overlay"
                      />
                    </div>
                    <img
                      loading="lazy"
                      src={require("../../assets/images/sections/dynasties/dynasty_overlay.png")}
                      alt="The Warri"
                    />
                    <div className="dynasty-text" id="warri">
                      <div>
                        <h3 className="dynasty-title">{"The Warri"}</h3>
                        <p className="dynasty-subtitle">{"The Hulking Vast"}</p>
                      </div>
                      <p className="dynasty-content">
                        An enigmatic race who branched off from humanity many generations ago, the
                        Warri live in the dangerous region of the Hulking Vast, where nature has
                        been affected by the dust from Mt. Shine and wildlife has run amok. The
                        Warri are unknown to the rest of Armoria, and their intentions — and
                        abilities — remain a mystery.
                      </p>
                    </div>
                  </div>
                </SwiperSlide>
              </Swiper>
            </div>
            <div className="m-0 mb-32 mt-64 reveal-from-bottom ta-c" data-reveal-delay="600"></div>
            <Image
              id="armorite11"
              style={{ transform: `translateY(${-offsetY * 0.2}px)` }}
              className="armorite"
              src={require("./../../assets/images/sections/dynasties/Armorite1.png")}
            />
            <Image
              id="armorite12"
              style={{ transform: `translateY(${-offsetY * 0.2}px)` }}
              className="armorite"
              src={require("./../../assets/images/sections/dynasties/Armorite2.png")}
            />
            <Image
              id="armorite13"
              style={{ transform: `translateY(${-offsetY * 0.2}px)` }}
              className="armorite"
              src={require("./../../assets/images/sections/dynasties/Armorite3.png")}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

Dynasties.propTypes = propTypes;
Dynasties.defaultProps = defaultProps;

export default Dynasties;
