import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import Image from "./Image";

const propTypes = {
  tag: PropTypes.elementType,
  color: PropTypes.string,
  size: PropTypes.string,
  loading: PropTypes.bool,
  wide: PropTypes.bool,
  wideMobile: PropTypes.bool,
  disabled: PropTypes.bool,
  imageUrl: PropTypes.string,
};

const defaultProps = {
  tag: "Dynastycomponent",
  color: "",
  size: "",
  loading: false,
  wide: false,
  wideMobile: false,
  imageUrl: "dynasty1.png",
  disabled: false,
};

const DynastyComponent = ({
  className,
  tag,
  color,
  size,
  loading,
  wide,
  wideMobile,
  disabled,
  imageUrl,
  title,
  subtitle,
  content,
  id,
  ...props
}) => {
  const classes = classNames(
    "dynasty-comp",
    loading && "is-loading",
    wide && "button-block",
    wideMobile && "button-wide-mobile",
    className
  );

  return (
    <div {...props} className={classes} disabled={disabled} id={id}>
      <div className="dynasty-img-container">
        <Image
          className="dynasty-img"
          src={require("./../../assets/images/sections/dynasties/" + imageUrl)}
          alt="KING COLOSSUS"
        />
        <img
          className="dynasty-overlay"
          src={require("../../assets/images/sections/dynasties/dynasty_overlay.png")}
          alt="Dynasty Overlay"
        />
      </div>
      <img
        loading="lazy"
        src={require("../../assets/images/sections/dynasties/dynasty_overlay.png")}
        alt="The Warri"
      />
      <div className="dynasty-text">
        <div>
          <h3 className="dynasty-title">{title}</h3>
          <p className="dynasty-subtitle">{subtitle}</p>
        </div>
        <p className="dynasty-content">{content}</p>
      </div>
    </div>
  );
};

DynastyComponent.propTypes = propTypes;
DynastyComponent.defaultProps = defaultProps;

export default DynastyComponent;
