import React, { useCallback, useContext, useEffect, useState } from "react";
import { map, distinctUntilChanged } from "rxjs";
import { setupWalletSelector } from "@near-wallet-selector/core";
import { setupModal } from "@near-wallet-selector/modal-ui";
import { setupNearWallet } from "@near-wallet-selector/near-wallet";
import { setupMyNearWallet } from "@near-wallet-selector/my-near-wallet";
import { setupSender } from "@near-wallet-selector/sender";
import { setupMathWallet } from "@near-wallet-selector/math-wallet";
import { setupNightly } from "@near-wallet-selector/nightly";
import { setupLedger } from "@near-wallet-selector/ledger";
import { setupWalletConnect } from "@near-wallet-selector/wallet-connect";
import { setupNightlyConnect } from "@near-wallet-selector/nightly-connect";
// import { CONTRACT_ID } from "../constants";
import nearWalletIconUrl from "@near-wallet-selector/near-wallet/assets/near-wallet-icon.png";
import myNearWalletIconUrl from "@near-wallet-selector/my-near-wallet/assets/my-near-wallet-icon.png";
import senderIconUrl from "@near-wallet-selector/sender/assets/sender-icon.png";
import mathWalletIconUrl from "@near-wallet-selector/math-wallet/assets/math-wallet-icon.png";
import nightlyIconUrl from "@near-wallet-selector/nightly/assets/nightly.png";
import ledgerIconUrl from "@near-wallet-selector/ledger/assets/ledger-icon.png";
import walletConnectIconUrl from "@near-wallet-selector/wallet-connect/assets/wallet-connect-icon.png";
import nightlyConnectIconUrl from "@near-wallet-selector/nightly-connect/assets/nightly-connect.png";

const WalletSelectorContext = React.createContext(null);

export const WalletSelectorContextProvider = ({ children }) => {
  const [selector, setSelector] = useState(null);
  const [modal, setModal] = useState(null);
  const [accounts, setAccounts] = useState([]);

  const init = useCallback(async () => {
    const _selector = await setupWalletSelector({
      network: (process.env.REACT_APP_NODE_ENV == "production") ? "mainnet" : "testnet",
      debug: true,
      modules: [
        setupNearWallet({
          iconUrl: nearWalletIconUrl
        }),
        setupMyNearWallet({
          iconUrl: myNearWalletIconUrl
        }),
        setupSender({
          iconUrl: senderIconUrl
        }),
        setupMathWallet({
          iconUrl: mathWalletIconUrl
        }),
        setupNightly({
          iconUrl: nightlyIconUrl
        }),
        setupLedger({
          iconUrl: ledgerIconUrl
        }),
        setupWalletConnect({
          projectId: "c4f79cc...",
          metadata: {
            name: "NEAR Wallet Selector",
            description: "Example dApp used by NEAR Wallet Selector",
            url: "https://github.com/near/wallet-selector",
            icons: [walletConnectIconUrl],
          },
          iconUrl: walletConnectIconUrl
        }),
        setupNightlyConnect({
          url: "wss://ncproxy.nightly.app/app",
          appMetadata: {
            additionalInfo: "",
            application: "NEAR Wallet Selector",
            description: "Example dApp used by NEAR Wallet Selector",
            icon: nightlyConnectIconUrl,
          },
          iconUrl: nightlyConnectIconUrl
        }),
      ],
    });
    const _modal = setupModal(_selector, { contractId: process.env.REACT_APP_CONTRACT_NAME });
    const state = _selector.store.getState();

    setAccounts(state.accounts);

    window.selector = _selector;
    window.modal = _modal;

    setSelector(_selector);
    setModal(_modal);
  }, []);

  useEffect(() => {
    init().catch((err) => {
      console.error(err);
      alert("Failed to initialise wallet selector");
    });
  }, [init]);

  useEffect(() => {
    if (!selector) {
      return;
    }

    const subscription = selector.store.observable
      .pipe(
        map((state) => state.accounts),
        distinctUntilChanged()
      )
      .subscribe((nextAccounts) => {
        setAccounts(nextAccounts);
      });

    return () => subscription.unsubscribe();
  }, [selector]);

  if (!selector || !modal) {
    return null;
  }

  const accountId =
    accounts.find((account) => account.active)?.accountId || null;

  return (
    <WalletSelectorContext.Provider
      value={{
        selector,
        modal,
        accounts,
        accountId,
      }}
    >
      {children}
    </WalletSelectorContext.Provider>
  );
};

export function useWalletSelector() {
  const context = useContext(WalletSelectorContext);

  if (!context) {
    throw new Error(
      "useWalletSelector must be used within a WalletSelectorContextProvider"
    );
  }

  return context;
}
