import React from "react";
// import sections
import Hero from "../components/sections/Hero";
import Introducing from "../components/sections/Introducing";
import Universe from "../components/sections/Universe";
import Dynasties from "../components/sections/Dynasties";
import Integrate from "../components/sections/Integrate";
import { ToastContainer } from "react-toastify";

const Home = () => {
  return (
    <>
      <Introducing />
      <Hero />
      <Universe />
      <Dynasties />
      <ToastContainer position="bottom-right" autoClose={5000} />
    </>
  );
};

export default Home;
