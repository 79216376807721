import React, { useEffect, useState } from 'react';
import getConfig from "../utils/config";
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import classNames from "classnames";
import Image from '../components/elements/Image';
import {useHistory} from 'react-router-dom';
import { view } from "../utils/utils";
import { useWalletSelector } from "../contexts/WalletSelectorContext";
import { allowedNodeEnvironmentFlags } from 'process';

const Comic = ({
    className,
    topOuterDivider,
    bottomOuterDivider,
    topDivider,
    bottomDivider,
    hasBgColor,
    invertColor,
    split,
    ...props}) => {
    
    let CONTRACT_NAME;

    const {accountId } = useWalletSelector();

    switch (props.match.params.contract) {
        case '0':
            CONTRACT_NAME = process.env.REACT_APP_CONTRACT_NAME;
            break;
        case '1':
            CONTRACT_NAME = process.env.REACT_APP_CONTRACT_NAME_1;
            break;
        case '2':
            CONTRACT_NAME = process.env.REACT_APP_CONTRACT_NAME_2;
            break;
        case '3':
            CONTRACT_NAME = process.env.REACT_APP_CONTRACT_NAME_3;
            break;
    
        default:
            break;
    }

    const [width, setWidth] = useState(window.innerWidth);

    const [pageCount, setPageCount] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);

    const [pdf, setPdf] = useState('');
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [jpegFile, setJpegFile] = useState('');
    const [isImmersive, setIsImmersive] = useState(true);
    const [video, setVideo] = useState();

    const navigation = useHistory();

    const outerClasses = classNames(
        "section center-content-mobile reveal-from-bottom",
        topOuterDivider && "has-top-divider",
        bottomOuterDivider && "has-bottom-divider",
        hasBgColor && "has-bg-color",
        invertColor && "invert-color",
        className
    );

    useEffect(() => {
        async function getComic() {
            const tokenData = await view(CONTRACT_NAME, "nft_token", {
                account_id: accountId,
                token_id: props.match.params.id,
            });

            if (CONTRACT_NAME == process.env.REACT_APP_CONTRACT_NAME && tokenData.metadata.pdf_media) {
                setJpegFile('https://bafybeiehsecp7vmson72fdn2ey5fvsju6zpuyymbuthtjqkoxliddmottu.ipfs.nftstorage.link/');
            } else {
                setJpegFile(JSON.parse(tokenData.metadata.extra).jpeg_media);
            } 

            setPdf(tokenData.metadata.pdf_media);
            setTitle(tokenData.metadata.title);
        }

        async function getVideo() {
            const videoTokenData = await view(CONTRACT_NAME, "nft_token", {
                account_id: accountId,
                token_id: props.match.params.id,
            });

            setVideo(JSON.parse(videoTokenData.metadata.extra).video);
            setTitle(videoTokenData.metadata.title);
            setDescription(videoTokenData.metadata.description);
        }
        //check if first issue
        if(props.match.params.id == 'issue-0') {
            setJpegFile('https://bafybeiehsecp7vmson72fdn2ey5fvsju6zpuyymbuthtjqkoxliddmottu.ipfs.nftstorage.link/')
            setPdf('https://nftstorage.link/ipfs/bafybeigz4qatlag3pcoy7a5f2powrxsxbtpgnrx35nniag74y6eqq5fxvi');
            setTitle('Issue 0 | Unlimited');
        } else if (props.match.params.id == 'issue-1') {
            setJpegFile('https://bafybeidkw5yalafhktwhbixroh5ch7aq4vjzhtdyycwtnea3rqpnpdu6hu.ipfs.nftstorage.link/')
            setPdf('https://bafybeih72yyf5exa3udbnsl7ot6f3d6nmpdw6aovrv3kqou46rhxmlh2na.ipfs.nftstorage.link/');
            setTitle('Issue 1 | Unlimited');
        } else if ((props.match.params.contract == '2' || props.match.params.contract == '3')) {
            getVideo();
        } else {
            getComic();
        }

    },[]);

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }
    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    let isMobile = width <= 768;

    const onDocumentLoadSuccess = ({numPages}) => {
        setPageCount(numPages);
    };

    const nextPage = () => {
        const atLastPage = currentPage === pageCount;

        if (atLastPage) {
            setCurrentPage(1);
        } else{
            setCurrentPage(currentPage+1)
        }
    }

    const previousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage-1)
        }
    };

    const immersiveView = () => (
        jpegFile
        ? <Image src={jpegFile} alt={title} />
        : [...Array(pageCount)].map((_, idx) => (
            <Page
                key={idx}
                pageNumber={idx + 1}
                className="pdfViewerPage"
                width={width}
            />
        ))
    )

    const desktopView = () => (
        <>
            <Page
                pageNumber={currentPage}
                className="pdfViewerPage"
                width={width < 1024 ? null : 530}
            />
            {
                (width > 1024 && (currentPage+1) <= pageCount) &&
                    <Page
                        pageNumber={currentPage + 1}
                        className="pdfViewerPage"
                        width={530}
                    />
            }
        </>
    )

    return (
        <section {...props} className={outerClasses}>
            {/* {!isMobile && ( */}
                <div className='topbar-wrapper'>
                    <div className='topbar-goback' onClick={() => navigation.goBack()}>
                        <img src={require('../assets/images/arrow-back.png')} alt="arrow" />
                        BACK
                    </div>
                    <div style={{textAlign: 'center'}}>
                        <p className='topbar-title'>{title}</p>
                        {!isImmersive && <span className='topbar-page'>page {currentPage} of {pageCount}</span>}
                    </div>
                    { (props.match.params.contract == '2' || props.match.params.contract == '3') ?<div className='topbar-switch'/>: 
                    <div className='topbar-switch' onClick={() => setIsImmersive(!isImmersive)}>
                        {`SWITCH ${isImmersive ? 'OFF' : 'TO'} IMMERSIVE VIEW`}
                    </div>}
                </div>
            {/* )} */}
            <div className="container">
                {(!isImmersive && !isMobile) && (
                <div className='controls'>
                    <button onClick={e=>previousPage()}>Previous</button>
                    <button style={{backgroundColor: '#308DE2', borderColor: "#1E6AAF"}} onClick={e=>nextPage()}>Next</button>
                </div>
                )}
                { pdf ? (
                    <Document
                        file={pdf}
                        onLoadSuccess={onDocumentLoadSuccess}
                        loading={<div style={{display:"flex", justifyContent: "center", alignItems:"center"}}>Loading Comic</div>}
                        className={`pdfViewerDocument ${isImmersive && 'flex-column'}`}
                    >
                        { (isImmersive || isMobile) ? immersiveView() : desktopView() }
                    </Document>
                ) : (
                    (props.match.params.contract === '2' || props.match.params.contract === '3') ? (
                        <div className='placeholder'>
                            <div className='header'>
                                <iframe className='avatar' src={video} frameBorder="0" scrolling='no'/>
                                <div className='title'>{title}</div>
                            </div>
                            <div className='footer'>
                            {description}
                            </div>
                        </div>
                    ) : (
                        <p style={{ textAlign: 'center' }}>Something went wrong.</p>
                    )
                )}
            </div>
        </section>
    );
}

export default Comic;
