import React, { useState, useRef, useEffect } from "react";
import classNames from "classnames";
import { SectionProps } from "../../utils/SectionProps";
import Button from "../elements/Button";
import Image from "../elements/Image";
import "react-toastify/dist/ReactToastify.css";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

import MintModal from "./partials/MintModal/MintModal";

const propTypes = {
  ...SectionProps.types,
};

const defaultProps = {
  ...SectionProps.defaults,
};

const Hero = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  ...props
}) => {
  const [mintPopup, setMintPopup] = useState(false);

  const [dropdown, setDropdown] = useState(false);
  const [currentIndex, setCurrentIndex] = useState("COMIC ISSUE 0");

  const outerClasses = classNames(
    "hero section center-content",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "hero-inner section-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  // handle /#claim scroll on load
  // timeout to handle element resizing on page load, after loading is cleared
  const claimRef = useRef();
  useEffect(() => {
    if (window.location.hash === "#claim" && claimRef.current) {
      const timeout = setTimeout(
        () => claimRef.current.scrollIntoView({ behavior: "smooth" }),
        500
      );
      return () => clearTimeout(timeout);
    }
  }, [claimRef]);

  useEffect(() => {
    const dropdownItems = Array.from(
      document.getElementsByClassName("swiper-pagination-bullet") ?? []
    );
    if (dropdownItems.length) {
      dropdownItems.forEach((el) =>
        el.addEventListener("click", () => setDropdown(true))
      );
    }
  }, []);

  return (
    <>
      <section ref={claimRef} id="claim" {...props} className={outerClasses}>
        <div className="claim-bg-fade">
          <div className="section-title">
            <h1>COMIC ISSUES</h1>
          </div>
          <div className="claim-container">
            {/* Issue card 0 */}
            <div className={innerClasses}>
              <div className="hero-carousel">
                <div className="hero-carousel-item">
                  <div className="hero-carousel-item-inner">
                    <div className="hero-carousel-item-inner-left">
                      <Image
                        className="comic-book-cover"
                        src={require("./../../assets/images/sections/hero/issue0cover-crop.png")}
                        alt="Comic Book Cover"
                      />
                    </div>
                    <div className="hero-carousel-item-inner-right">
                      <div>
                        <div className="title reveal-from-bottom">
                          <h1>ISSUE #0</h1>
                        </div>

                        <div
                          className="copy reveal-from-bottom"
                          data-reveal-delay="400"
                        >
                          <p>
                            Get introduced to the world of{" "}
                            <span>Armored Kingdom</span> with issue 0 of our
                            first in the comic series.
                          </p>
                        </div>
                      </div>
                      <div className="btn-group">
                        <a
                          target="_blank"
                          rel="noreferrer"
                          className="btn-read"
                          href={require("./../../assets/pdfs/akcomicIssue0.pdf")}
                        >
                          Read now
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* End of Issue card 0 */}
            {/* Issue card 1 */}
            <div className={innerClasses}>
              <div className="hero-carousel">
                <div className="hero-carousel-item">
                  <div className="hero-carousel-item-inner">
                    <div className="hero-carousel-item-inner-left">
                      <Image
                        className="comic-book-cover"
                        src={require("./../../assets/images/sections/hero/issuecover1.png")}
                        alt="Comic Book Cover"
                      />
                    </div>
                    <div className="hero-carousel-item-inner-right">
                      <div>
                        <div className="title reveal-from-bottom">
                          <h1>ISSUE #1</h1>
                        </div>
                        <div
                          className="copy reveal-from-bottom"
                          data-reveal-delay="400"
                        >
                          <p>
                            Now that you've entered the world of{" "}
                            <span>Armored Kingdom</span> with Issue 0, continue
                            on the journey and delve into Issue 1.
                          </p>
                        </div>
                      </div>
                      <div className="btn-group">
                        <a
                          target="_blank"
                          rel="noreferrer"
                          className="btn-read"
                          href={require("./../../assets/pdfs/akcomicIssue1.pdf")}
                        >
                          Read now
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* End of Issue card 1 */}
            {/* Issue card 1 */}
            {/*
            <div className={innerClasses}>
              <div className="hero-carousel">
                <div className="hero-carousel-item">
                  <div className="hero-carousel-item-inner">
                    <div className="hero-carousel-item-inner-left">
                      <Image
                        className="comic-book-cover"
                        src={require('./../../assets/images/sections/hero/issue2cover-crop.png')}
                        alt="Comic Book Cover"
                      />
                    </div>
                    <div className="hero-carousel-item-inner-right">
                      <div>
                        <div className="title reveal-from-bottom">
                          <h1>ISSUE #2</h1>
                        </div>
                        <div
                        className="copy reveal-from-bottom"
                        data-reveal-delay="400"
                        >
                          <p>
                            Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore .late.
                          </p>
                        </div>
                      </div>
                      <div className="btn-group">
                        <button 
                          onClick={() => setMintPopup(true)}
                          className="btn-mint"
                        >
                          Mint Now
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            */}
            {/* End of Issue card 1 */}
          </div>
          <div className="container-fluid hero-bottom">
            <div className={innerClasses}>
              <div
                className="hero-figure reveal-from-bottom pt-32 pr-0"
                data-reveal-value="20px"
                data-reveal-delay="200"
              >
                <Image
                  className=""
                  src={require("./../../assets/images/sections/hero/ComicBook1.png")}
                  alt="Sideways Comic Book"
                />
              </div>
              <div
                className="hero-content launch-drop ta-l"
                style={{ marginTop: "0px" }}
              >
                <p className="reveal-from-bottom" data-reveal-delay="200">
                  Get updates on our next issue release dates
                </p>
                <div
                  className="input-group reveal-from-bottom"
                  data-reveal-delay="300"
                >
                  <form
                    action="https://www.getrevue.co/profile/armoredkingdom/add_subscriber"
                    method="post"
                    id="revue-form"
                    name="revue-form"
                    target="_blank"
                  >
                    <input
                      type="email"
                      placeholder="Enter your email address"
                      className="subscribe-input"
                    />
                    <Button>SUBMIT</Button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {mintPopup && <MintModal setMintPopup={setMintPopup} />}
    </>
  );
};

Hero.propTypes = propTypes;
Hero.defaultProps = defaultProps;

export default Hero;
