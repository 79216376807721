/* eslint-disable react/jsx-no-duplicate-props */
import React, { useState, useRef, useEffect, useCallback } from "react";
import { providers } from "near-api-js";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Link } from "react-router-dom";
import Logo from "./partials/Logo";

import { useWalletSelector } from "../../contexts/WalletSelectorContext";
//import getConfig from "../../utils/config";
//const { networkId } = getConfig(process.env.REACT_APP_NODE_ENV || "development");

const propTypes = {
  navPosition: PropTypes.string,
  hideNav: PropTypes.bool,
  hideSignin: PropTypes.bool,
  bottomOuterDivider: PropTypes.bool,
  bottomDivider: PropTypes.bool,
};

const defaultProps = {
  navPosition: "",
  hideNav: false,
  hideSignin: false,
  bottomOuterDivider: false,
  bottomDivider: false,
};

const Header = ({
  className,
  navPosition,
  hideNav,
  hideSignin,
  bottomOuterDivider,
  bottomDivider,
  ...props
}) => {
  const { selector, modal, accounts, accountId } = useWalletSelector();
  const [account, setAccount] = useState(null);

  const [isActive, setIsactive] = useState(false);
  const nav = useRef(null);
  const hamburger = useRef(null);

  const getAccount = useCallback(async (): Promise => {
    if (!accountId) {
      return null;
    }

    const { network } = selector.options;
    const provider = new providers.JsonRpcProvider({ url: network.nodeUrl });

    return provider
      .query({
        request_type: "view_account",
        finality: "final",
        account_id: accountId,
      })
      .then((data) => ({
        ...data,
        account_id: accountId,
      }));
  }, [accountId, selector.options]);

  useEffect(() => {
    if (!accountId) {
      return setAccount(null);
    }

    // setLoading(true);

    getAccount().then((nextAccount) => {
      console.log(nextAccount);
      setAccount(nextAccount);
      // setLoading(false);
    });

    isActive && openMenu();
    document.addEventListener("keydown", keyPress);
    document.addEventListener("click", clickOutside);
    // document.addEventListener('scroll', onScroll);
    return () => {
      document.removeEventListener("keydown", keyPress);
      document.removeEventListener("click", clickOutside);
      closeMenu();
    };
  }, [accountId, getAccount]);

  const isHomePage = window.location.pathname === "/";

  const openMenu = () => {
    document.body.classList.add("off-nav-is-active");
    nav.current.style.maxHeight = nav.current.scrollHeight + 20 + "px";
    nav.current.style.height = nav.current.scrollHeight + 20 + "px";
    setIsactive(true);
  };

  const closeMenu = () => {
    document.body.classList.remove("off-nav-is-active");
    nav.current && (nav.current.style.maxHeight = null);
    nav.current && (nav.current.style.height = null);
    setIsactive(false);
  };

  const keyPress = (e) => {
    isActive && e.keyCode === 27 && closeMenu();
  };

  const clickOutside = (e) => {
    if (!nav.current) return;
    if (
      !isActive ||
      nav.current.contains(e.target) ||
      e.target === hamburger.current
    )
      return;
    closeMenu();
  };

  const classes = classNames(
    "site-header fixed-site-header",
    bottomOuterDivider && "has-bottom-divider",
    className
  );

  const handleSignIn = () => {
    closeMenu();
    modal.show();
  };

  const handleSignOut = async () => {
    const wallet = await selector.wallet();

    wallet.signOut().catch((err) => {
      console.log("Failed to sign out");
      console.error(err);
    });
  };

  return (
    <header {...props} className={classes}>
      <div className="site-header-blur" />
      <div className="container-fluid">
        <div
          className={classNames(
            "site-header-inner",
            bottomDivider && "has-bottom-divider"
          )}
        >
          <Logo />
          {!hideNav && (
            <>
              <button
                ref={hamburger}
                className="header-nav-toggle"
                onClick={isActive ? closeMenu : openMenu}
              >
                <span className="screen-reader">Menu</span>
                <span className="hamburger">
                  <span className="hamburger-inner"></span>
                </span>
              </button>
              <nav
                ref={nav}
                className={classNames("header-nav", isActive && "is-active")}
              >
                <div className="header-nav-inner">
                  {!hideSignin && (
                    <ul className="list-reset header-nav-right text-xs">
                      <li className={account ? "dropdown" : ""}>
                        <div
                          className="button button-primary hover:scale-125"
                          style={{ width: 150, fontSize: 18 }}
                        >
                          <a
                            href="https://www.roblox.com/games/14292025947/Armored-Kingdom"
                            target="_blank"
                            rel="noreferrer"
                            className="btn-read"
                            style={{ fontWeight: "bold", fontSize: "20px" }}
                          >
                            PLAY GAME
                          </a>
                        </div>
                        <div className="dropdown-content">
                          <Link to="/profile">Profile</Link>
                          <Link to="#" onClick={handleSignOut}>
                            Logout
                          </Link>
                        </div>
                      </li>
                    </ul>
                  )}
                </div>
              </nav>
            </>
          )}
        </div>
      </div>
    </header>
  );
};

Header.propTypes = propTypes;
Header.defaultProps = defaultProps;

export default Header;
