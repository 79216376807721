import axios from 'axios';
import React, { useRef, useState, useEffect } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { toast } from 'react-toastify';
import { validateEmail } from '../../../../utils/utils';
import Button from '../../../elements/Button';
import Image from '../../../elements/Image';
import Input from '../../../elements/Input';
import LoadingScreen from '../../../elements/LoadingScreen';
import style from './MintModal.module.scss';
import backIcon from '../../../../assets/images/arrow-back.png';
import check from '../../../../assets/images/white-checkmark.png';

const MintModal = ({ setMintPopup }) => {
  const [userType, setUserType] = useState('');
  const [email, setEmail] = useState();
  const [address, setAddress] = useState();
  const [nftMsg, setNftMsg] = useState(null);
  const [submitMessage, setSubmitMessage] = useState(null);
  const {isSubmitMessageVisible, setSubmitMessageVisible} = useState(false);
  const [loading, setLoading] = useState(false);

  const reCaptchaRef = useRef();
  const recaptchaSiteKey = '6Lf7lXsgAAAAAK34WnPfX30hw78U4IUiR6RLXZ-G';

  const api = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL + '/linkdrop/api',
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
    },
  });

  const requestNewUser = (recaptchaToken) => {
    return api.request({
      url: '/emailNftUrl/',
      method: 'POST',
      data: {
        email,
        address,
        recaptchaToken,
      },
    });
  };

  const requestExistingUser = (recaptchaToken) => {
    return api.request({
      url: '/airdropOptIn',
      method: 'POST',
      data: {
        email,
        recaptchaToken,
      },
    });
  };

  // clear message when modal view changes
  useEffect(() => {
    setNftMsg(null);
    setSubmitMessage(null);
  }, [userType]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    setNftMsg(null);

    if (!email || validateEmail(email)) {
      return setNftMsg('Please enter a valid email');
    }

    if (userType === 'newUser' && !address) {
      return setNftMsg('Please enter a NEAR address');
    }

    const token = await reCaptchaRef.current.executeAsync();

    setLoading(true);
    (userType === 'newUser' ? requestNewUser : requestExistingUser)(token)
      .then((res) => {
        userType === 'newUser' 
          ? setNftMsg(<>Please check your inbox <span className="green-check"><Image src={check}/></span></>) 
          : setNftMsg(<>Verified. You’ve been successfully added to the airdrop list. <span className="green-check"><Image src={check}/></span></>);
        
        // toast.success('You’ve been added to the waitlist',{
        //   position: 'bottom-right',
        //   autoClose: 10000,
        // });
        // setMintPopup(false);
      })
      .catch((err) => {
        if (userType === "newUser") {
          setNftMsg("Something went wrong.");
        } else {
          setNftMsg("Wallet not found, Please register as a new user.");
        }
        // toast.error(
        //   err.response.data ? err.response.data.msg : 'Something went wrong',{
        //     position: 'bottom-right',
        //     autoClose: 10000,
        //   }
        // );
      })
      .finally(() => {
        setLoading(false);
        reCaptchaRef.current.reset();
      });
  };

  return (
    <>
      <div className="mint-popup">
        
        <div className="mint-popup-header">
          {
            (userType) ?
            <div className="back-button" onClick={e=>setUserType('')}><Image src={backIcon}/>Back</div>
            : <div className="back-button"></div>
          }
          
          <div
            className="close-button"
            onClick={(e) => setMintPopup(false)}
          ></div>
        </div>
        {(loading) 
          ? <div className="loading-wrapper"><LoadingScreen transparent /></div>
          :
        <>
        <div className="content">

            <div className="left-content">
                  <Image
                    src={require('./../../../../assets/images/sections/hero/issue2cover-crop.png')}
                    alt="Comic Book Cover"
                  />
              </div>
            <div className="right-content">
              <div className="text-content">
                <div className="title">CLAIM COMIC ISSUE 2 AIRDROP</div>
                <div className="description">
                  {!userType
                    ? 'Choose a method to authenticate and guarantee your comic NFT'
                    : 'You will be sent an e-mail confirming your airdrop for one comic NFT. Airdrops will periodically occur over the next 14 days'}
                </div>
              </div>
              <div className="bottom-content">
                <div className="right-content">
                  <form style={{ flexDirection: 'column' }}>
                    {userType && (
                      <Input
                        id="member_email"
                        placeholder="Enter your email address"
                        type="email"
                        formGroup="desktop"
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    )}
                    {userType === 'newUser' && (
                      <Input
                        id="member_email"
                        placeholder="Near wallet address"
                        type="text"
                        formGroup="desktop"
                        onChange={(e) => setAddress(e.target.value)}
                      />
                    )}
                    <span className="submit-message">{!!nftMsg && nftMsg}</span>
                    <ReCAPTCHA
                      sitekey={recaptchaSiteKey}
                      size="invisible"
                      ref={reCaptchaRef}
                      className="recaptcha"
                    />
                    {!userType && (
                      <>
                        <div className={style.buttonWrapper}>
                          <Button onClick={() => setUserType('existingUser')}>
                            Existing Collector
                          </Button>
                          <Button onClick={() => setUserType('newUser')}>
                            New Collector
                          </Button>
                        </div>
                        <span className="create-wallet">
                          Don't have NEAR wallet?<br/>
                          <a
                            href="https://wallet.near.org/create"
                            rel="noreferrer"
                            target="_blank"
                          >
                            Create one now
                          </a>
                        </span>
                      </>
                    )}
                    {userType && (
                      <div className="button-wrapper mobile-wrap">
                        <Button type="submit" onClick={(e) => handleSubmit(e)}>
                          SUBMIT
                        </Button>
                        <span className="create-wallet">
                          Don't have NEAR wallet?<br/>
                          <a
                            href="https://wallet.near.org/create"
                            rel="noreferrer"
                            target="_blank"
                          >
                            Create one now
                          </a>
                        </span>
                      </div>

                    )}
                  </form>
                </div>
              </div>
            </div>
        </div>
        </>}
        <div className="footer">
          <p>
            This site is protected by reCAPTCHA and the <a target="_blank" rel="noreferror" href='https://policies.google.com/privacy?hl=en'>Google Privacy Policy</a> and <a target="_blank" rel="noreferror" href="https://policies.google.com/terms?hl=en">Terms of Service</a> apply
          </p>
        </div>
      </div>
    </>
  );
};

export default MintModal;
