import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { SectionTilesProps } from "../../utils/SectionProps";
import SectionHeader from "./partials/SectionHeader";
import Image from "../elements/Image";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Button from "../elements/Button";

const propTypes = {
  ...SectionTilesProps.types,
};

const defaultProps = {
  ...SectionTilesProps.defaults,
};
const Introducing = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {
  const [offsetY, setOffsetY] = useState(0);

  const handleScroll = () => setOffsetY(window.pageYOffset);

  useEffect(() => {
    const error = new URLSearchParams(window.location.search).get("error");
    if (error) {
      toast.error(error, {
        position: "bottom-right",
        autoClose: 5000,
      });
    }
    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const outerClasses = classNames(
    "features-tiles section",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "features-tiles-inner section-inner p-0",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  const sectionHeader = {
    title: "INTRODUCING",
    paragraph: "ARMORED KINGDOM",
  };

  return (
    <section {...props} className={outerClasses}>
      <div className="pos-relative">
        <div className={innerClasses}>
          {/* <Image
            id="intro_logo"
            src={require("./../../assets/images/sections/introducing/Logo_hd.png")}
            alt="Armorite"
          /> */}
          <video autoPlay muted playsInline className="intro-video">
            <source
              src={require("../../assets/images/sections/introducing/Header_new_ak_new.mp4")}
              type="video/mp4"
            />
            Your browser does not support the video tag.
          </video>
          <div
            className="mb-32 reveal-from-bottom ta-c pr-16 pl-16 intro-title"
            data-reveal-delay="400"
          >
            <h4
              className="m-0 mb-16"
              style={{
                textShadow: "8px 8px 4px rgba(0, 0, 0, 0.2)",
              }}
            >
              WHERE LEGENDS ARE CREATED
            </h4>
            <p
              className="m-0 mb-16"
              style={{
                textShadow: "8px 8px 4px rgba(0, 0, 0, 0.2)",
              }}
            >
              A Multi-Platform, Immersive Universe
            </p>

            <div
              className="button button-primary hover:scale-125"
              style={{ width: 150, fontSize: 18 }}
            >
              <a
                href="https://www.roblox.com/games/14292025947/Armored-Kingdom"
                target="_blank"
                rel="noreferrer"
                className="btn-read"
                style={{ fontWeight: "bold", fontSize: "20px" }}
              >
                PLAY GAME
              </a>
            </div>
          </div>
        </div>
        <Image
          id="armorite1"
          style={{ transform: `translateY(${-offsetY * 0.5}px)` }}
          className="pos-absolute"
          src={require("./../../assets/images/sections/introducing/Armorite1.png")}
          alt="Armorite"
        />
        <Image
          id="armorite2"
          style={{ transform: `translateY(${-offsetY * 0.5}px)` }}
          className="pos-absolute"
          src={require("./../../assets/images/sections/introducing/Armorite2.png")}
          alt="Armorite"
        />
        <Image
          id="armorite3"
          style={{ transform: `translateY(${-offsetY * 0.5}px)` }}
          className="pos-absolute"
          src={require("./../../assets/images/sections/introducing/Armorite3.png")}
          alt="Armorite"
        />
        <Image
          id="armorite4"
          style={{ transform: `translateY(${-offsetY * 0.5}px)` }}
          className="pos-absolute"
          src={require("./../../assets/images/sections/introducing/Armorite4.png")}
          alt="Armorite"
        />
        <Image
          id="armorite1to4"
          style={{ transform: `translateY(${-offsetY * 0.5}px)` }}
          className="pos-absolute"
          src={require("./../../assets/images/sections/introducing/Armorite1to4_mobile.png")}
          alt="Armorite"
        />
      </div>
    </section>
  );
};

Introducing.propTypes = propTypes;
Introducing.defaultProps = defaultProps;

export default Introducing;
